import Header from "../Navbar";
import Slider from "react-slick";
import "./index.css";
import React, { useState } from "react";
import Footer from "../Footer";
import workpermit from "../../Images/Images/Work-Permit.jpg";
import visa from "../../Images/Images/Visa.jpg";
import studentvisa from "../../Images/Images/Student-Visa.jpg";
import AirTicketing from "../../Images/Images/AIRTicketing.jpg";
import certificate from "../../Images/Images/Certificate Attestation.jpg";
import emailjs from "emailjs-com";
import video5 from "../../Videos/video5.mp4";
import EnterpriseCard from "../Card";

// other imports...

// const Data = [
//   {
//     heading: "GLAMPING ADVENTURE",
//     text: "Whether you’re looking to get away with a special someone or arranging a family holiday, our popular Glamping Adventure is a great choice. Take a break from everyday life and create new memories with our all-inclusive package.",
//     image:
//       "https://static.wixstatic.com/media/11062b_1b9a20ab037949588913f92e862724e7~mv2.jpg/v1/fill/w_850,h_575,fp_0.50_0.50,q_85,usm_0.66_1.00_0.01,enc_auto/11062b_1b9a20ab037949588913f92e862724e7~mv2.jpg",
//   },
//   {
//     heading: "SOUTHEAST ASIA CULINARY TOUR",
//     text: "If you're looking for an unforgettable experience, then our Southeast Asia Culinary Tour is a superb option. Our agents will take care of all the details including logistics and reservations so you won't have to worry about a thing. Get in touch today for more information.",
//     image:
//       "https://static.wixstatic.com/media/11062b_c8d04c3ddbcd4b68bcc9d72e141faa0c~mv2.jpg/v1/fill/w_850,h_625,fp_0.50_0.50,q_85,usm_0.66_1.00_0.01,enc_auto/11062b_c8d04c3ddbcd4b68bcc9d72e141faa0c~mv2.jpg",
//   },
//   {
//     heading: "BAVARIAN CASTLE TOUR",
//     text: "The best thing about planning a trip is getting away from it all, which is why our Bavarian Castle Tour is the best choice for you. We are here to take care of everything so you can sit back and start vacationing from the moment you book your tickets. Contact us to learn about our exclusive Bavarian Castle Tour.",
//     image:
//       "https://static.wixstatic.com/media/11062b_636d090e7c96417bb179966996a1f84c~mv2_d_4566_3047_s_4_2.jpg/v1/fill/w_850,h_625,fp_0.50_0.50,q_85,usm_0.66_1.00_0.01,enc_auto/11062b_636d090e7c96417bb179966996a1f84c~mv2_d_4566_3047_s_4_2.jpg",
//   },
//   {
//     heading: "FOUR-DAY CARIBBEAN CRUISE",
//     text: "This is a special package offered exclusively by our Tour Agency. Treat yourself to a one-of-a-kind adventure you’ll never forget. Places are limited—contact us today to make sure you don’t miss out on an experience of a lifetime.",
//     image:
//       "https://static.wixstatic.com/media/11062b_5278a8263c174b21bcaaf72004d7dc6b~mv2.jpg/v1/fill/w_850,h_575,fp_0.50_0.50,q_85,usm_0.66_1.00_0.01,enc_auto/11062b_5278a8263c174b21bcaaf72004d7dc6b~mv2.jpg",
//   },
//   {
//     heading: "WHALE WATCHING TOUR",
//     text: "If you enjoy touring new destinations with a professional guide, then our Whale Watching Tour is a great choice. pardesoverseasconsultancy is networked with certified, local tour guides who are ready to serve your every need and reveal sights that others don’t get to see.",
//     image:
//       "https://static.wixstatic.com/media/11062b_4231675cbafd48069f20d2f7efdf558a~mv2.jpg/v1/fill/w_850,h_625,fp_0.50_0.50,q_85,usm_0.66_1.00_0.01,enc_auto/11062b_4231675cbafd48069f20d2f7efdf558a~mv2.jpg",
//   },
// ];
// import ReactPlayer from "react-player";

import DocumentAss from "../../Images/Images/Documenation assistance.jpg";
import tour from "../../Images/Images/Tour.jpg";
import GlobalVisa from "../../Images/Images/Global Visa.jpg";
import macca from "../../Images/Images/mosque.jpg";
import allcountry from "../../Images/Images/All country visa process.png";
import usa from "../../Images/Images/usa.jpg";

const reviews = [
  {
    name: "Mohan",
    review:
      "Exceptional service! The overseas consultancy team guided me through every step of the visa process with great expertise. They made what could have been a complex and daunting experience incredibly smooth.",
  },
  {
    name: "Abdulla",
    review:
      "Highly recommended! The consultants were not only knowledgeable about the immigration procedures but also provided valuable insights into life abroad. They personalized their approach, addressing my concerns promptly.",
  },
  {
    name: "Srinivas",
    review:
      "I appreciate the professionalism of this consultancy. From document preparation to interview coaching, they were thorough and meticulous. I felt well-prepared and confident throughout the entire application process.",
  },
  {
    name: "Umer",
    review:
      "The overseas consultancy exceeded my expectations. The team was attentive to my needs, providing timely updates and clarifications. Their attention to detail and commitment to client satisfaction stood out.",
  },
  {
    name: "Praveen",
    review:
      "Fantastic support! The consultants demonstrated a deep understanding of various immigration policies. Their proactive communication and commitment to transparency made me feel secure about my decision.",
  },
  {
    name: "Sohail Khan",
    review:
      "Great customer service! The staff were friendly, patient, and willing to go the extra mile. They made sure I understood the requirements and were always available to address my queries promptly.",
  },
  {
    name: "Md Kaleem",
    review:
      "I'm impressed by the efficiency of this consultancy. The entire process was streamlined, and they provided clear instructions. I'm grateful for the guidance, making my transition to a new country hassle-free.",
  },
  {
    name: "Sharukh",
    review:
      "Top-notch consultancy! They have a team of knowledgeable professionals who understand the nuances of immigration. Their commitment to client success sets them apart. Definitely recommend their services.",
  },
  {
    name: "Fatima Ahmed",
    review:
      "The overseas consultancy was instrumental in making my dream of studying abroad a reality. They not only assisted with the paperwork but also provided valuable insights into the educational system and culture of the destination country.",
  },
  {
    name: "Rahul",
    review:
      "Excellent guidance! The consultants were not only well-versed in the immigration process but also provided valuable tips for settling in a new country. Their holistic approach made the entire experience positive and stress-free.",
  },
];

const Enterprisesettings = {
  dots: true,
  infinite: true,
  speed: 5000,
  slidesToShow: 4, // Show 3 slides in large devices
  slidesToScroll: 1,
  arrows: true,
  autoplay: true,
  responsive: [
    {
      breakpoint: 1400,
      settings: {
        slidesToShow: 3,
      },
    },
    {
      breakpoint: 1000,
      settings: {
        slidesToShow: 2,
      },
    },
    {
      breakpoint: 768,
      settings: {
        slidesToShow: 1,
        centerMode: true,
        variableWidth: false,
        arrows: false,
      },
    },
  ],
};

const { send } = emailjs;
const Data = [
  {
    heading: "VISA PROCESSING",
    text: "Our Visa Processing services ensure a smooth and efficient application process. Whether you're planning a business trip, a leisurely tour, or pursuing education abroad, we provide comprehensive support for visa applications across various countries. Let us take care of the details, making your journey seamless and stress-free.",
    image: visa, // Replace with the actual path to your visa processing image
  },
  {
    heading: "GLOBAL VISA",
    text: "Simplify your international travel with our Global Visa services. We provide comprehensive support for visa applications across various countries, ensuring a smooth and efficient process.",
    image: GlobalVisa,
  },
  {
    heading: "VFS/USA SLOTS APPOINTMENT",
    text: "Navigating the visa application process involves scheduling appointments efficiently. Our VFS/USA Slots Appointment services help you secure timely appointments, streamlining the process for a swift and organized submission of your visa application.",
    image: usa,
  },
  {
    heading: "TOUR PACKAGES",
    text: "Explore the world with our meticulously crafted Tour Packages. Whether you seek adventure, relaxation, or cultural experiences, our tour packages cater to diverse interests and preferences.",
    image: tour,
  },
  {
    heading: "TOUR/BUSINESS VISA",
    text: "Whether you're planning a business trip or a leisurely tour, our Tour/Business Visa services ensure a hassle-free application process. We assist you in obtaining the necessary visa for your travel, making your journey seamless and stress-free.",
    image: visa,
  },
  {
    heading: "WORK PERMIT",
    text: "Securing a work permit is a crucial step in your career abroad. Our Work Permit services guide you through the application process, ensuring all necessary documentation is in order, and helping you obtain the permit required for your employment.",
    image: workpermit,
  },

  {
    heading: "DOCUMENTATION ASSISTANCE",
    text: "From visa applications to work permits, our Documentation Assistance services provide comprehensive support. We help you gather and organize the necessary documents, ensuring a smooth and efficient process.",
    image: DocumentAss,
  },
  {
    heading: "PR PROFILE",
    text: "Achieving Permanent Residency is a significant milestone. Our PR Profile services assist you in creating a compelling Permanent Residency profile, maximizing your chances of success in the application process.",
    image:
      "https://static.wixstatic.com/media/11062b_1b9a20ab037949588913f92e862724e7~mv2.jpg/v1/fill/w_850,h_575,fp_0.50_0.50,q_85,usm_0.66_1.00_0.01,enc_auto/11062b_1b9a20ab037949588913f92e862724e7~mv2.jpg",
  },
  {
    heading: "CERTIFICATE ATTESTATION",
    text: "Ensuring the authenticity of your documents is vital for international processes. Our Certificate Attestation services authenticate your certificates, making them legally acceptable for various purposes abroad.",
    image: certificate,
  },
  {
    heading: "AIR TICKETING",
    text: "Planning your journey includes securing the best airfare. Our Air Ticketing services offer competitive rates and convenient booking options, ensuring you reach your destination with ease.",
    image: AirTicketing,
  },
  {
    heading: "All COUNTRY VISA PROCESSING",
    text: "Our All Country Visa Processing services cover a wide spectrum of destinations. Whether you're traveling for business, education, or leisure, we facilitate visa processing for countries worldwide.",
    image: allcountry,
  },

  {
    heading: "HAJ & UMRAH PACKAGES",
    text: "Embark on a spiritual journey with our Haj and Umrah Packages. Our packages are designed to provide a seamless pilgrimage experience, handling the logistics so you can focus on your spiritual endeavors.",
    image: macca,
  },
  {
    heading: "STUDENT VISA",
    text: "Pursuing education abroad is an exciting endeavor. Our Student Visa services assist you in obtaining the required visa for your academic journey, offering guidance on documentation and ensuring compliance with regulations.",
    image: studentvisa,
  },
];

const watsappUrl = process.env.REACT_APP_WHATSAPPLINK;

const Home = () => {
  const [formData, setFormData] = useState({
    firstname: "",
    lastname: "",
    email: "",
    phone: "",
    comments: "",
  });
  // const [selectedItem, setSelectedItem] = useState(null);
  // const itemRefs = useRef(Data.map(() => React.createRef()));

  // const scrollToItem = (index) => {
  //   if (itemRefs.current[index] && itemRefs.current[index].current) {
  //     itemRefs.current[index].current.scrollIntoView({
  //       behavior: "smooth",
  //     });
  //   }
  // };

  // useEffect(() => {
  //   if (selectedItem !== null) {
  //     scrollToItem(selectedItem);
  //   }
  // }, [selectedItem]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    // try {
    //   console.log(formData, process.env.REACT_APP_FETCH_URL);
    //   const response = await fetch(`${process.env.REACT_APP_FETCH_URL}/users`, {
    //     method: "POST",
    //     headers: {
    //       "Content-Type": "application/json",
    //     },
    //     body: JSON.stringify(formData),
    //   });

    //   if (!response.ok) {
    //     throw new Error("Network response was not ok");
    //   }

    //   const result = await response.json();
    //   console.log(result);
    //   setFormData({
    //     firstname: "",
    //     lastname: "",
    //     email: "",
    //     phone: "",
    //     comments: "",
    //   });
    //   alert(result.message);
    // } catch (error) {
    //   console.error("Error during API call:", error.message);
    // }

    send(
      process.env.REACT_APP_SERVICE_ID, // Service ID
      process.env.REACT_APP_TEMPLATE_ID, // Template ID
      formData,
      process.env.REACT_APP_PUBLIC_API_KEY // Public Key - https://dashboard.emailjs.com/admin/account
    )
      .then((response) => {
        alert("Thank you for Reaching us. We will get back to you soon");
        setFormData({
          firstname: "",
          lastname: "",
          email: "",
          phone: "",
          comments: "",
        });
        console.log("SUCCESS!", response.status, response.text);
      })
      .catch((err) => {
        console.log("FAILED...", err);
      });
  };

  return (
    <>
      <Header />
      <div>
        <div className="home-page-container1">
          <h1 className="home-page-container1-heading">
            PARDES OVERSEAS CONSULTANCY
          </h1>
          {/* <div className="video-container">
            <ReactPlayer
              width="100%"
              height="100%"
              url="https://youtube.com/shorts/G9FnxzqmHdM?si=Ifn3oQmWlyfTuY5-"
              className="video-container1"
              controls
              playing
            />
          </div> */}

          <p
            style={{ marginTop: "30px" }}
            className="home-page-container1-text"
          >
            TOURIST VISA, BUSINESS VISA, STUDENT VISA, WORK PERMIT, PERMANENT
            RESIDENCY
          </p>
          <h1
            style={{ marginTop: "30px" }}
            className="home-page-container2-heading"
          >
            PROCESSING & DOCUMENTATION ASSISTANCE
          </h1>
          <p
            style={{ marginTop: "30px" }}
            className="home-page-container1-text"
          >
            Since 2016, we’ve been helping clients plan adventures beyond their
            wildest dreams. With the knowledge and tools to find the best deals
            and expertise in every corner of the world, our team is here to make
            your travel experience as easy and enjoyable as possible. Browse our
            offerings below.
          </p>
        </div>
        <div className="home-page-container2">
          <video style={{ margin: "20px" }} height="350" controls autoPlay>
            <source src={video5} type="video/mp4" />
            Your browser does not support the video tag.
          </video>
          <h1 className="home-page-container2-heading">OUR SERVICES</h1>
          <div className="list-container">
            <div>
              <p className="home-page-container2-text">TOUR/BUSINESS</p>
              <p className="home-page-container2-text">VISA</p>
              <p className="home-page-container2-text">WORK PERMIT</p>
              <p className="home-page-container2-text">
                VFS/USA SLOTS APPOINTMENT
              </p>
              <p className="home-page-container2-text">Visa Processing</p>
              <p className="home-page-container2-text">
                DOCUMENTATION ASSISTANCE
              </p>
              <p className="home-page-container2-text">PR PROFILE</p>
              {/* {Data.map((each, index) => (
                <p
                  key={index}
                  className="home-page-container2-text"
                  onClick={(e) => {
                    e.preventDefault();
                    setSelectedItem(index);
                  }}
                >
                  {each.heading}
                </p>
              ))} */}
            </div>
            <div>
              <p className="home-page-container2-text">
                CERTIFICATE ATTESTATION
              </p>
              <p className="home-page-container2-text">AIR TICKETING</p>
              <p className="home-page-container2-text">TOUR PACKAGES</p>
              <p className="home-page-container2-text">
                ALL COUNTRY VISA PROCESSING
              </p>
              <p className="home-page-container2-text">GLOBAL VISA</p>
              <p className="home-page-container2-text">HAJ & UMRAH PACKAGES</p>
              <p className="home-page-container2-text">VISA PROCESSING</p>
            </div>
          </div>
          {Data.map((each) => {
            return (
              <div className="home-page-container2-subcontainer">
                <div className="home-page-container2-subcontainer-sub1">
                  <h1 className="home-page-container2-subcontainer-sub-heading">
                    {each.heading}
                  </h1>
                  <p className="home-page-container2-subcontainer-sub-text">
                    {each.text}
                  </p>
                </div>
                <div className="home-page-container2-subcontainer-sub2">
                  <img
                    className="home-page-container2-subcontainer-image"
                    src={each.image}
                    alt={each.image}
                  />
                </div>
              </div>
            );
          })}
        </div>
        <div className="home-page-container3">
          <img
            alt="image1"
            className="home-page-container3-image"
            src="https://static.wixstatic.com/media/11062b_3fbcd4c77d7f49b2a6fd5aa752287b75~mv2.jpg/v1/fill/w_1175,h_550,al_c,q_85,usm_0.66_1.00_0.01,enc_auto/City%20River.jpg"
          />
          <h1 className="home-page-container2-heading">
            Explore your dream destinations like USA, UK, Canada, Australia,
            Europe, Japan, South Korea, etc.
          </h1>
          <p className="home-page-container1-text">
            Kindly complete the form below, and rest assured, we will reach out
            to you promptly.
          </p>
          <form className="home-page-form1" onSubmit={handleSubmit}>
            <div className="home-page-form1-container1">
              <div className="home-page-form1-input-container">
                <label className="home-page-form1-label">First Name</label>
                <input
                  name="firstname"
                  onChange={handleChange}
                  className="home-page-form1-input"
                  required
                />
              </div>
              <div className="home-page-form1-input-container">
                <label className="home-page-form1-label">Last Name</label>
                <input
                  name="lastname"
                  onChange={handleChange}
                  className="home-page-form1-input"
                  required
                />
              </div>
              <div className="home-page-form1-input-container">
                <label className="home-page-form1-label">Email</label>
                <input
                  name="email"
                  onChange={handleChange}
                  className="home-page-form1-input"
                  required
                />
              </div>
              <div className="home-page-form1-input-container">
                <label className="home-page-form1-label">Phone</label>
                <input
                  name="phone"
                  onChange={handleChange}
                  className="home-page-form1-input"
                  required
                />
              </div>
            </div>
            <div className="home-page-form1-textarea-container">
              <label className="home-page-form1-label">Comments</label>
              <textarea
                name="comments"
                onChange={handleChange}
                className="home-page-form1-textarea"
              />
            </div>
            <button type="submit" className="home-page-form1-button">
              Submit
            </button>
          </form>
        </div>
        <div className="home-page-container4">
          <img
            alt="image2"
            className="home-page-container3-image"
            src="https://static.wixstatic.com/media/11062b_9bd8397246ed4ffba4c477f3507afa7a~mv2.jpg/v1/fill/w_1175,h_550,al_c,q_85,usm_0.66_1.00_0.01,enc_auto/Solo%20Traveler.jpg"
          />
          <h1 className="home-page-container2-heading">
            ABOUT PARDES OVERSEAS CONSULTANCY
          </h1>
          <p className="home-page-container1-text">
            Tour/Business Visa For Usa Canada Australia Japan New Zealand
            Etc..Processing Assistance...USA 🇺🇸 Europe Ofc Slots Available
          </p>
          <a
            className="whatsapp-button"
            href={watsappUrl}
            rel="noreferrer"
            target="_blank"
          >
            <button className="home-page-form1-button">Contact Us</button>
          </a>

          {/* <form
            className="home-page-form2"
            action="https://formsubmit.co/your@email.com"
            method="POST"
          >
            <h1 className="home-page-form2-heading">Subscribe Form</h1>
            <p className="home-page-form2-text">Stay up to date</p>
            <input
              className="home-page-form2-input"
              placeholder="Email Address"
            />
            <button className="home-page-form2-button" type="submit">
              Submit
            </button>
          </form> */}
        </div>
        {/* <div className="home-page-container5">
          <div className="home-page-container5-sub1">
            <h1>CONTACT US</h1>
          </div>
          <div className="home-page-container5-sub2">
            <img
              className="home-page-container5-image"
              src="https://static.wixstatic.com/media/11062b_8ef7248269774f02b46091fb5d36236d~mv2.jpg/v1/fill/w_850,h_986,fp_0.59_0.34,q_85,usm_0.66_1.00_0.01,enc_auto/11062b_8ef7248269774f02b46091fb5d36236d~mv2.jpg"
            />
          </div>
        </div> */}
      </div>
      <div className="Enterprise-beginner-slider-container">
        <h1
          style={{ textAlign: "center" }}
          className="home-page-container2-heading"
        >
          Our Customer Reviews
        </h1>

        <Slider {...Enterprisesettings} className="Enterprise-beginner-slider">
          {reviews.map((each) => (
            <EnterpriseCard key={each.id} cardDetails={each} />
          ))}
        </Slider>
      </div>
      <Footer />
    </>
  );
};

export default Home;
