import "./index.css";
import image1 from "../../Images/Images/new logo.jpg";
import { FaInstagram, FaYoutube, FaLinkedin } from "react-icons/fa";
const Footer = () => {
  return (
    <div className="Ecommerce-Website-Footer-Section-bg pb-5 pt-5">
      <div className="container">
        <div className="row">
          <div className="col-12 col-md-6 col-lg-6">
            <div className="d-flex flex-column justify-conent-start justify-conent-md-start ">
              <div className="text-md-left text-center mb-3">
                <img
                alt="logo"
                  src={image1}
                  className="Ecommerce-Website-Footer-Section-logo"
                />
              </div>
              <p style={{fontWeight:"600"}}>Reach Out to Us</p>
              <div className="d-flex flex-row justify-content-md-start justify-conent-center  mb-3">
                {/* <div className="Ecommerce-Website-Footer-Section-logo-card mr-2">
                  <a href="https://www.google.com/" target="_blank">
                    <i className="fa fa-google" aria-hidden="true"></i>{" "}
                  </a>
                </div> */}
                
                <div className="Ecommerce-Website-Footer-Section-logo-card mr-2">
                  <a
                    href="https://www.youtube.com/@pardesoverseasconsultancy"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <FaYoutube style={{ color: "red", fontSize: "25px" }} />
                  </a>
                </div>
                <div className="Ecommerce-Website-Footer-Section-logo-card mr-2">
                  <a
                    href="https://instagram.com/pardes_overseas_consultancy?igshid=NzZlODBkYWE4Ng=="
                    target="_blank"
                    rel="noreferrer"
                  >
                    <FaInstagram
                      style={{ color: "#fa7e1e", fontSize: "25px" }}
                    />
                  </a>
                </div>
                <div className="Ecommerce-Website-Footer-Section-logo-card">
                  <a
                    href="https://www.linkedin.com/in/syed-ali-mehmoodi-39b169243"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <FaLinkedin style={{ color: "blue", fontSize: "25px" }} />
                  </a>
                </div>
              </div>
              {/* <div className="text-center text-md-left">
                <p>Mobile No: 8074138572</p>

                <p style={{margin:"0px"}}>Email: pardesoverseasconsultancy@gmail.com</p>
                <p style={{ marginLeft: "50px", marginTop:"0px" }}>
                  info@pardesoverseasconsultancy.com
                </p>
                 <p>info@pardesoverseasconsultancy@gmail.com</p> 
                <h1 className="Ecommerce-Website-Footer-Section-address">
                  Opp Qadaria Masjid, Saidabad Colony, Hyderabad
                </h1>
              </div> */}
            </div>
          </div>
          <div class="col-12 col-md-6 col-lg-6">
            <p style={{fontWeight:"600"}}>Contact Us</p>
            <p>Mobile No: 8074138572</p>

            <p style={{ margin: "0px" }}>
              Email: pardesoverseasconsultancy@gmail.com
            </p>
            <p style={{ marginLeft: "50px", marginTop: "0px" }}>
              info@pardesoverseasconsultancy.com
            </p>
            {/* <p>info@pardesoverseasconsultancy@gmail.com</p> */}
            <h1 className="Ecommerce-Website-Footer-Section-address">
              Opp Qadaria Masjid, Saidabad Colony, Hyderabad
            </h1>
          </div>
          {/* <div>
              <h1 class="Ecommerce-Website-Footer-Section-heading">
                Get to know us
              </h1>
              <p>About us</p>
              <p>Career</p>
              <p>Press Releases</p>
              <p>Gift a smile</p>
            </div>
          </div> */}
          {/* <div class="col-6 col-md-6 col-lg-6">
            <h1 class="Ecommerce-Website-Footer-Section-heading">
              Contact with us
            </h1>
            <div className="footer-links">
              <div>
                <a
                  href="https://www.linkedin.com/in/syed-ali-mehmoodi-39b169243"
                  target="_blank"
                  className="link-name"
                >
                  LinkedIn
                </a>
              </div>
              <div>
                <a
                  className="link-name"
                  href="https://www.youtube.com/@pardesoverseasconsultancy"
                  target="_blank"
                >
                  YouTube
                </a>
              </div>
              <div>
                <a
                  className="link-name"
                  href="https://instagram.com/pardes_overseas_consultancy?igshid=NzZlODBkYWE4Ng=="
                  target="_blank"
                >
                  Instagram
                </a>
              </div>
            </div>
          </div> */}
          {/* <div class="col-6 col-md-6 col-lg-3">
            <div>
              <h1 class="Ecommerce-Website-Footer-Section-heading">
                Let Us Help You
              </h1>
              <p>100% Purchase</p>
              <p>Protection</p>
              <p>Your Account</p>
              <p>Return Center</p>
              <p>Help</p>
            </div>
          </div> */}
        </div>
        <hr class="Ecommerce-Website-Footer-Section-line" />
        <div class="d-flex flex-row justify-content-center">
          {/* <div class="Ecommerce-Website-Footer-Section-copy-right">
            <i class="fa fa-copyright" aria-hidden="true"></i>
          </div> */}
          <div class="ml-2 mt-1">
            <h1 class="Ecommerce-Website-Footer-Section-address">
              Copyright @2023 By pardes overseas consultancy
            </h1>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Footer;
