import React from "react";
import Header from "../Navbar";
import Footer from "../Footer";
import "./index.css";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import video1 from "../../Videos/video1.mp4";
import video2 from "../../Videos/video2.mp4";
import video3 from "../../Videos/video3.mp4";
import video4 from "../../Videos/video4.mp4";
import video5 from "../../Videos/video5.mp4";
import video6 from "../../Videos/video6.mp4";
import video7 from "../../Videos/video7.mp4";
import video8 from "../../Videos/video8.mp4";
import video9 from "../../Videos/video9.mp4";
import video10 from "../../Videos/video10.mp4";
import video11 from "../../Videos/video11.mp4";


import image5 from "../../Images/5.png";
import image3 from "../../Images/3.png";
// import image2 from "../../Images/2.png";
// import image4 from "../../Images/4.png";
// import image5 from "../../Images/5.png";
// import image6 from "../../Images/6.png";
import image7 from "../../Images/7.png";
import image8 from "../../Images/8.png";
import image9 from "../../Images/9.png";
import image10 from "../../Images/10.png";
import image11 from "../../Images/11.png";
import image12 from "../../Images/12.png";
import image14 from "../../Images/14.png";
import image15 from "../../Images/15.png";
import image16 from "../../Images/16.png";
// import image18 from "../../Images/18.png";
import image19 from "../../Images/19.png";
import image20 from "../../Images/20.png";
import image21 from "../../Images/21.png";
// import image22 from "../../Images/22.png"
import image23 from "../../Images/23.png";
import image25 from "../../Images/25.png";
import image26 from "../../Images/26.png";
import image27 from "../../Images/27.png";
import image29 from "../../Images/29.png";
import image30 from "../../Images/30.png";
// import image32 from "../../Images/32.png";
import image33 from "../../Images/33.png";
// import image34 from "../../Images/34.png";
// import image36 from "../../Images/36.png";
// import image37 from "../../Images/37.png";
import image38 from "../../Images/38.png";
// import image39 from "../../Images/39.png";
// import image40 from "../../Images/40.png";
import image41 from "../../Images/41.png";
const ImagesList = [
  { image: image9 },
  { image: image10 },
  { image: image11 },
  { image: image12 },
  { image: image14 },
  { image: image15 },
  { image: image16 },
  { image: image19 },
  { image: image20 },
  { image: image7 },
  { image: image3 },
  { image: image5 },
  { image: image8 },
];

const SecondImagesList = [
  { image: image21 },
  { image: image23 },
  { image: image25 },
  { image: image26 },
  { image: image27 },
  { image: image29 },
  { image: image30 },
  { image: image33 },
  { image: image38 },
  { image: image41 },
  { image: image9 },
  { image: image10 },
  { image: image11 },
];

const Blog = () => {
  const settings = {
    dots: true,
    infinite: true,
    speed: 5000,
    slidesToShow: 3, // Show 3 slides in large devices
    slidesToScroll: 1,
    arrows: true,
    autoplay: true,
    responsive: [
      {
        breakpoint: 1400,
        settings: {
          slidesToShow: 2, // Show 1 slide in small devices (e.g., tablets and mobiles)
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1, // Show 1 slide in small devices (e.g., tablets and mobiles)
        },
      },
    ],
  };
  return (
    <>
      <Header />
      <hr />
      <div className="blog-maincontainer">
        <h1 className="blog-heading1">VISAS</h1>
        <div>
          <video style={{ margin: "20px" }} width="300" height="250" controls>
            <source src={video2} type="video/mp4" />
            Your browser does not support the video tag.
          </video>
          <video style={{ margin: "20px" }} width="300" height="250" controls>
            <source src={video3} type="video/mp4" />
            Your browser does not support the video tag.
          </video>
          <video style={{ margin: "20px" }} width="300" height="250" controls>
            <source src={video4} type="video/mp4" />
            Your browser does not support the video tag.
          </video>
          <video style={{ margin: "20px" }} width="300" height="250" controls>
            <source src={video8} type="video/mp4" />
            Your browser does not support the video tag.
          </video>
        </div>
        <h1 className="blog-heading1">OVERVIEW</h1>
        <div>
          <video style={{ margin: "20px" }} width="300" height="250" controls>
            <source src={video5} type="video/mp4" />
            Your browser does not support the video tag.
          </video>
          <video style={{ margin: "20px" }} width="300" height="250" controls>
            <source src={video6} type="video/mp4" />
            Your browser does not support the video tag.
          </video>

          <video style={{ margin: "20px" }} width="300" height="250" controls>
            <source src={video11} type="video/mp4" />
            Your browser does not support the video tag.
          </video>
          <video style={{ margin: "20px" }} width="300" height="250" controls>
            <source src={video1} type="video/mp4" />
            Your browser does not support the video tag.
          </video>
        </div>
        <h1 className="blog-heading1">DETAILS</h1>
        <div>
          <video style={{ margin: "20px" }} width="300" height="250" controls>
            <source src={video7} type="video/mp4" />
            Your browser does not support the video tag.
          </video>

          <video style={{ margin: "20px" }} width="300" height="250" controls>
            <source src={video9} type="video/mp4" />
            Your browser does not support the video tag.
          </video>
          <video style={{ margin: "20px" }} width="300" height="250" controls>
            <source src={video10} type="video/mp4" />
            Your browser does not support the video tag.
          </video>
        </div>
        <h1 className="blog-heading1">SUCCESS STORIES</h1>
        <Slider {...settings} style={{ marginTop: "50px" }}>
          {ImagesList.map((each) => (
            <img
              className="blog-scroll-image"
              src={each.image}
              alt="scrollimage"
            />
          ))}
        </Slider>
        <Slider {...settings} style={{ marginTop: "30px" }}>
          {SecondImagesList.map((each) => (
            <img
              className="blog-scroll-image"
              src={each.image}
              alt="scrollimage"
            />
          ))}
        </Slider>
      </div>
      <Footer />
    </>
  );
};

export default Blog;
