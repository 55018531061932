import "./index.css";
function EnterpriseCard(props) {
  const { cardDetails } = props;

  const { name,review } = cardDetails;
  return (
    <div className="Enterprise-card-skill-main-container">
        <h3 className="Enterprise-card-skill-main-heading">
          {name}
        </h3>
        <p className="Enterprise-card-skill-main-description">
          {review}
        </p>
    </div>
  );
}

export default EnterpriseCard;
