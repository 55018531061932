
import './index.css'
import {Link} from "react-router-dom"
import image1 from "../../Images/Images/new logo.jpg";
import {AiFillHome} from "react-icons/ai"
import { FaBlog } from "react-icons/fa";
const Header = props => {
  
  return (
    <>
    <nav className="nav-header">
      <div className="nav-content">
        <div className="nav-bar-mobile-logo-container">
          
             <img
              className="website-logo"
              src={image1}
              alt="website logo"
            />
          
          <ul className="nav-bar-mobile-icons-container">
            <li>
              <Link to="/"> 
                 <AiFillHome className="nav-item-mobile-link" /> 
               </Link> 
            </li> 
             <li>
               <Link to="/blog"> 
                 <FaBlog className="nav-item-mobile-link" /> 
              </Link> 
            </li>
             <li>
              <button
                type="button"
                className="nav-mobile-btn"
              >
              </button>
            </li>
          </ul>
        </div>
        <div className="nav-bar-large-container">
             <img
              className="website-logo"
              src={image1}
              alt="website logo"
            /> 
          <ul className="nav-menu">
            <li className="nav-menu-item">
               <Link to="/" className="nav-link">
                Home
                </Link>
            </li>
            <li className="nav-menu-item">
              <Link to="/blog" className="nav-link">
               Blog
              </Link> 
            </li>
          </ul>
          {/* <button
            type="button"
            className="logout-desktop-btn"
            
          >
            Logout
          </button> */}
        </div>
        
      </div>
    </nav>
    </>
  )
}

export default Header